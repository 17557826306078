import React from "react";
import Block from "./block";

const HeroTeam = ({ text }) => {
  return (
    <Block>
      <div className="bloom-ht">{text}</div>
    </Block>
  );
};

export default HeroTeam;
