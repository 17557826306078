import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroTeam from "../components/heroTeam";
import Block from "../components/block";
import BackgroundImage from "gatsby-background-image";
import Subfooter from "../components/subfooter";
import Img from "gatsby-image";

function WorkPage({ location, data }) {
  const content = data.workPage.frontmatter;
  const teamPhotos = data.teamPage.frontmatter;

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <HeroTeam
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
        text={content.hero.heroText}
      />
      <Block className={`bloom-bg-white`}>
        <div className={`bloom-mb-5 bloom-flex`}>
          <h2
            className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
          >
            {content.title}
          </h2>
        </div>
        <div className={`bloom-flex bloom-flex-wrap bloom--mx-2`}>
          {content.projects.project.map((project, index) => {
            return (
              <div
                className={`bloom-flex bloom-w-full md:bloom-w-1/2 lg:bloom-w-1/3 bloom-p-2 bloom-mb-4`}
              >
                <a
                  className={`bloom-card bloom-flex bloom-flex-col bloom-w-full`}
                  href={project.linkURL}
                >
                  <div
                    className={`bloom-h-56 bloom-w-full bloom-bg-seafoam bloom-mb-2`}
                  >
                    {project.image && (
                      <BackgroundImage
                        backgroundColor={`transparent`}
                        className={`bloom-h-56 bloom-w-full`}
                        fluid={project.image.childImageSharp.fluid}
                        Tag="section"
                      ></BackgroundImage>
                    )}
                  </div>
                  <div className="bloom-p-6">
                    <h3
                      className={`bloom-text-2xl bloom-font-bold bloom-leading-tight bloom-uppercase bloom-mb-1`}
                    >
                      {project.title}
                    </h3>
                    <div
                      className={`bloom-font-bold bloom-text-green bloom-text-lg bloom-leading-tight bloom-mb-2`}
                    >
                      {project.client}
                    </div>
                    <div className={`bloom-prose bloom-mb-2`}>
                      {project.description}
                    </div>
                    <div className={`bloom-link`}>{project.linkTitle}</div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </Block>
      <Block>
        <div
          className={`bloom-flex bloom-flex-col md:bloom-flex-row bloom-flex-wrap bloom-mx-2 bloom-py-6 bloom-items-center`}
        >
          <div className="bloom-pr-0 md:bloom-pr-8 bloom-mb-2 sm:bloom-mb-0 bloom-w-1/5 md:bloom-w-2/12">
            <Img fluid={content.pollinator.image.childImageSharp.fluid} />
          </div>
          <div className="bloom-px-2 bloom-mb-2 sm:bloom-mb-0 bloom-w-full md:bloom-w-3/4">
            <h2 className="bloom-text-3xl bloom-my-0">Pollinator</h2>
            <p className="bloom-text-lg bloom-mb-4 md:bloom-mb-2">
              {content.pollinator.description}
            </p>
            <a
              className="bloom-inline-block sm:bloom-block bloom-w-full md:bloom-w-2/5 bloom-mt-4 bloom-bg-green bloom-border-none bloom-text-white bloom-text-center bloom-p-5 bloom-leading-tight bloom-font-bold hover:bloom-bg-white hover:bloom-text-green"
              href="https://medium.com/pollinator"
            >
              {content.pollinator.ctaTitle}
            </a>
          </div>
        </div>
      </Block>
      <Subfooter></Subfooter>
    </Layout>
  );
}

export const workPageQuery = graphql`
  query WorkTemplate {
    workPage: markdownRemark(frontmatter: { templateKey: { eq: "work" } }) {
      html
      frontmatter {
        title
        hero {
          heroText
        }
        pollinator {
          ctaTitle
          image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
        projects {
          project {
            image {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            client
            description
            title
            linkTitle
            linkURL
          }
        }
      }
    }
    teamPage: markdownRemark(frontmatter: { templateKey: { eq: "team" } }) {
      html
      frontmatter {
        people {
          person {
            name
            title
            image {
              childImageSharp {
                fluid(
                  maxWidth: 800
                  maxHeight: 800
                  duotone: {
                    highlight: "#ffffff"
                    shadow: "#cccccc"
                    opacity: 100
                  }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default WorkPage;
