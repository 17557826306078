import React from "react";
import Block from "./block";
import Img from "gatsby-image";

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function Subfooter({ people, children }) {
  let peopleArray = people ? shuffle(people) : [];

  return (
    <div>
      {peopleArray.length > 0 && (
        <Block className={`bloom-bg-white`}>
          <div className="bloom-flex">
            <h2
              className={`bloom-text-4xl bloom-uppercase bloom-text-grey-darkest bloom-font-bold bloom-mb-5 bloom-border-b-4 bloom-border-green`}
            >
              Our Team
            </h2>
          </div>
          <div className={`bloom-text-xl bloom-mb-12`}>
            As public servants we’ve brought best practices in technology and
            design to bear on the most difficult challenges in the public
            sector. If you’d like to join the team,{` `}
            <a
              className={`bloom-font-bold bloom-underline hover:bloom-text-green`}
              href="/jobs"
            >
              check out our open positions.
            </a>
          </div>
          <div className="bloom-flex bloom-flex-wrap bloom-relative">
            <div
              className={`bloom-absolute bloom-inset-0 bloom-flex bloom-items-center bloom-justify-center bloom-z-20`}
            >
              <a
                className={`bloom-bg-green bloom-border-none bloom-text-white bloom-mr-3 bloom-px-20 bloom-py-5 bloom-leading-tight bloom-font-bold bloom-shadow-md hover:bloom-bg-white hover:bloom-text-green`}
                href="/team"
              >
                Meet the team
              </a>
            </div>
            <div className={`bloom-flex bloom-w-full sm:bloom-w-1/2`}>
              <div className={`bloom-w-1/2`}>
                <Img fluid={peopleArray[0].image.childImageSharp.fluid} />
              </div>
              <div className={`bloom-w-1/2`}>
                <Img fluid={peopleArray[1].image.childImageSharp.fluid} />
              </div>
            </div>
            <div className={`bloom-flex bloom-w-full sm:bloom-w-1/2`}>
              <div className={`bloom-w-1/2`}>
                <Img fluid={peopleArray[2].image.childImageSharp.fluid} />
              </div>
              <div className={`bloom-w-1/2`}>
                <Img fluid={peopleArray[3].image.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </Block>
      )}
      {children}
      <Block className={`bloom-bg-green`} id="contact">
        <div className="bloom-flex">
          <h2
            className={`bloom-text-4xl bloom-uppercase bloom-text-white bloom-font-bold bloom-mb-5 bloom-border-b-4 bloom-border-white`}
          >
            Get in touch
          </h2>
        </div>
        <div className="bloom-flex bloom-flex-wrap bloom--mx-4">
          <div className="bloom-w-full bloom-px-4">
            <div className="bloom-text-white bloom-leading-tight bloom-mb-1">
              <strong>Let us know how we can help:</strong>
            </div>
            <div className="bloom-text-white">
              <a
                className="bloom-border-white bloom-border-b hover:bloom-text-black-full hover:bloom-border-black-full"
                href="mailto:hello@bloomworks.digital"
              >
                hello@bloomworks.digital
              </a>
            </div>
          </div>
        </div>
      </Block>
    </div>
  );
}

export default Subfooter;
